import React, { useState, useEffect } from 'react'
import Parser from 'html-react-parser'
import styled from 'styled-components'
import axios from 'axios'
import { base_url } from '../helper/urls'
import { motion } from 'framer-motion'

const KozerdekuAdatok = () => {
  const [allasok, setAllasok] = useState([])
  const [palyazatok, setPalyazatok] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get(
          `${base_url}/api/allasaink?sort=Cim%3Aasc&populate=*`
        )
        setAllasok(response.data.data)
      } catch (error) {
        console.log(error)
      }
      try {
        const response = await axios.get(
          `${base_url}/api/uj-palyazats`, {
            params: {
              "sort[Cim]": "asc",    
              "populate": "*", 
              "pagination[pageSize]": 100,
            },
          }
        )
        setPalyazatok(response.data.data);
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }

    fetchData()
  }, [])
  return (
    <Wrapper>
      <div className='felhivasok-fo-tarolo'>
        {isLoading ? (
          <div className='loading'></div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 0.3,
            }}>
            <div className='felhivasok-tarolo'>
              <h1 className='allasaink-focim'>Állásaink</h1>
              {allasok.map(function (item, i) {
                return (
                  <div className='felhivas-tarolo' key={i}>
                    <h2 className='felhivas-cim'>
                      {Parser(item.attributes.Cim)}
                    </h2>

                    <a
                      className='felhivas-letoltes-tarolo'
                      href={'/allasok/' + item.id}>
                      <div className='felhivas-letoltes'>Megtekintés</div>
                    </a>
                  </div>
                )
              })}
            </div>
            <div className='felhivasok-tarolo'>
              <h1 className='palyazataink-focim'>Pályázataink</h1>
              {palyazatok.map(function (item, i) {
                return (
                  <div className='felhivas-tarolo' key={i}>
                    <h2 className='felhivas-cim'>{item.attributes.Cim}</h2>

                    <a
                      className='felhivas-letoltes-tarolo'
                      href={'/palyazatok/' + item.id}>
                      <div className='felhivas-letoltes'>Megtekintés</div>
                    </a>
                  </div>
                )
              })}
            </div>
          </motion.div>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  background-color: var(--light-grey);
  .felhivasok-fo-tarolo {
    max-width: 1232px;
    width: 64%;
    margin-top: 250px;
    .loading {
      min-height: 100vh;
    }
    .allasaink-focim,
    .palyazataink-focim {
      margin-bottom: 2%;
      font-size: var(--main-title-font-size);
      font-weight: 800;
    }
    .felhivasok-tarolo {
      margin-bottom: 7%;
    }
    .felhivas-tarolo {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      border-radius: 35px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      padding-left: 8.44%;
      padding-right: 8.44%;
      padding-top: 3%;
      padding-bottom: 3%;
      margin-bottom: 1%;
      background-color: var(--primary-bg);
    }
    .felhivas-cim {
      width: 70%;
      font-size: var(--title-font-size);
      font-weight: 800;
    }
    .felhivas-letoltes-tarolo {
      background-color: var(--box-color);
      width: 30%;
      border-radius: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2%;
      padding-bottom: 2%;
      transition: all 0.3s;
    }
    .felhivas-letoltes-tarolo:hover {
      background-color: var(--box-color-hover);
    }
    .felhivas-letoltes {
      font-size: var(--button-font-size);
      color: var(--text-color);
      font-weight: 700;
    }
  }

  @media screen and (max-width: 1024px) {
    .felhivasok-fo-tarolo {
      width: 94%;
      margin-top: 2.5rem;

      .allasaink-focim,
      .palyazataink-focim {
        font-size: var(--title-font-size);
        text-align: center;
        margin-bottom: 1.5rem;
      }

      .felhivas-tarolo {
        flex-direction: column;
        padding: 1.5rem;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;

        .felhivas-cim {
          font-size: var(--small-text-font-size);
          width: 100%;
          text-align: center;
        }

        .felhivas-letoltes-tarolo {
          width: 80%;
          margin-top: 1rem;
          padding: 1rem 0;
        }
      }
    }
  }
`

export default KozerdekuAdatok
