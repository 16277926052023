import React from 'react'
import styled from 'styled-components'
import big_tree from '../assets/images/big-tree.svg'
import rolunk1 from '../assets/images/rolunk1.webp'
import rolunk1_mobil from '../assets/images/rolunk1-mobil.webp'
import rolunk2 from '../assets/images/rolunk2.png'
import rolunk3 from '../assets/images/rolunk3.webp'
import rolunk3_mobil from '../assets/images/rolunk3-mobil.webp'
import rolunk4 from '../assets/images/rolunk-tree.svg'
import { motion } from 'framer-motion'

const AboutUs = () => {
  return (
    <Wrapper>
      <motion.div
        className='rolunk-fo-tarolo'
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.3,
        }}>
        <div className='tarsasagunkrol'>
          <div className='tarsasagunkrol-text'>
            <h1>Társaságunkról</h1>
            <p>
              A KÖZPARK Kispesti Köztisztasági és Közfoglalkoztatási Nonprofit
              Korlátolt Felelősségű Társaságot (KÖZPARK Nonprofit Kft.) Budapest
              Főváros XIX.ker. Kispest Önkormányzata alapította 2008-ban.
            </p>
            <p>
              Társaságunk elsősorban az Önkormányzat által ránk bízott
              közfeladatokat végzi el, de szabad kapacitás függvényében egyedi
              lakossági megrendeléséknek is eleget tesz.
            </p>
            <p>
              Fő tevékenységünket, mely köztisztasági, parkfenntartási és
              városüzemeltetési feladatokból áll, igyekszünk minél magasabbb
              színvonalon ellátni - lehetőségeinkhez mérten - fejleszteni.
            </p>
          </div>
          <div>
            <img src={rolunk1} alt='lomtalanítás' className='desktop-img' />
            <img src={rolunk1_mobil} alt='lomtalanítás' className='mobil-img' />
          </div>
        </div>
        <div className='tarsasagunk-celja-wrapper'>
          <div className='tarsasagunk-celja-content'>
            <div className='tarsasagunk-celja-image'>
              <img src={rolunk2} alt='utcaseprés' />
            </div>
            <div className='tarsasagunk-celja-text'>
              <h2>A társaság célja:</h2>
              <p>
                Célunk, hogy folyamatosan tájékoztassuk a lakosságot az általunk
                elvégzett, illetve folyamatban lévő munkálatokról, amelyekkel
                még szebbé és élhetőbbé igyekszünk tenni a kerületet az itt élők
                és az ide látogatók számára.
              </p>
              <p>
                Közösségi értékeink megőrzése mellett nagy hangsúlyt fektetünk a
                közösségi létből eredő feladatok fejlesztésére, ehhez pedig
                szívesen fogadjuk a lakosság észrevételeit, javaslatait.
              </p>
              <p>
                Munkatársaink folyamatosan azon dolgoznak, hogy megvalósítsuk
                cégünk célkitűzéseit és eleget tegyünk a lakossági
                észrevételeknek, kéréseknek.
              </p>
            </div>
          </div>
        </div>
        <div>
          <img
            src={rolunk3}
            alt='kertrendezés'
            className='rolunk3-desktop-img'
          />
          <img
            src={rolunk3_mobil}
            alt='kertrendezés'
            className='rolunk3-mobil-img'
          />
        </div>
      </motion.div>
      <div className='trees'>
        <img src={rolunk4} alt='background' />
        <p>
          Társaságunk az önkormányzat által ránk bízott feladatokat - a
          Közfoglalkoztatási Program keretén belül - a munkaügyi központ által
          közvetített közfoglalkoztatottakkal együtt végzi el. Ezáltal
          elősegítjük a munkerőpiacon hátrányos helyzetű rétegek
          foglalkoztatását.
        </p>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  @media screen and (min-width: 1024px) {
    background-image: url(${big_tree});
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 200px;
    width: 100%;
    padding-top: 300px;
  }

  .rolunk-fo-tarolo {
    max-width: 1232px;
    width: 64%;
    margin-top: 2.5rem;

    @media screen and (max-width: 1024px) {
      width: 94%;
    }

    h1 {
      font-size: var(--main-title-font-size);
      padding-bottom: 2.5rem;
    }

    p {
      padding-bottom: 2rem;
      font-size: var(--paragraph-font-size);
    }

    h2 {
      color: var(--secondary-bg);
      font-style: italic;
      padding-bottom: 2.5rem;
    }

    .tarsasagunkrol {
      display: flex;
      flex-direction: row;

      @media screen and (max-width: 1024px) {
        flex-direction: column;
      }

      .desktop-img {
        height: 512px;
        padding-left: 7.5rem;

        @media screen and (max-width: 1024px) {
          display: none;
        }
      }

      .mobil-img {
        display: none;

        @media screen and (max-width: 1024px) {
          display: block;
          width: 100%;
          margin: auto;
        }
      }
    }

    .tarsasagunk-celja-wrapper {
      display: flex;
      align-items: center;
      align-content: center;

      .tarsasagunk-celja-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;

        @media screen and (max-width: 1024px) {
          flex-direction: column;
        }

        .tarsasagunk-celja-image {
          width: 50%;

          @media screen and (max-width: 1024px) {
            width: 100%;
          }
        }

        .tarsasagunk-celja-text {
          display: flex;
          flex-direction: column;
          width: 50%;

          @media screen and (max-width: 1024px) {
            width: 100%;
          }
        }
      }

      h2 {
        padding-bottom: 1.5rem;
      }

      @media screen and (max-width: 1024px) {
        flex-direction: column-reverse;
        align-items: center;
      }

      div {
        @media screen and (max-width: 1024px) {
          padding-right: 0;
          padding-top: 1rem;

          p:last-child {
            padding-bottom: 0;
          }
        }
      }

      img {
        padding-right: 3.5rem;
        width: 100%;

        @media screen and (max-width: 1024px) {
          width: 100%;
          margin: auto;
          padding-right: 0;
          padding-top: 0;
        }
      }
    }

    .rolunk3-desktop-img {
      padding-top: 7.5rem;
      width: 100%;
      display: block;

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    .rolunk3-mobil-img {
      display: none;

      @media screen and (max-width: 1024px) {
        display: block;
        width: 100%;
        margin: auto;
        padding-top: 2.6rem;
      }
    }
  }
  .trees {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-top: 11rem;

    @media screen and (max-width: 1024px) {
      padding-top: 2rem;
    }

    img {
      padding-right: 6%;
      width: 100%;

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    p {
      padding-right: 19%;
      font-size: var(--paragraph-font-size);

      @media screen and (max-width: 1024px) {
        padding-right: 0;
        width: 94%;
        margin: auto;
      }
    }
  }
`

export default AboutUs
